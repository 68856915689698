.type-of-client-form {
    height: 16px;
    display: block;
    position: relative;
    padding-left: 26px;
    margin-right: 32px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 20px !important;
    color: #68717A !important;
}

.type-of-client-form input {
    display: none;
}

.type-of-client-form input:checked~.checkmark {
    background-color: #5AB6DF;
    border: 1px solid #5AB6DF;
}

.type-of-client-form input:checked~.checkmark:after {
    display: block;
}

.type-of-client-form .checkmark:after {
    top: 5px;
    left: 5px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #FFF;
}

.business-user-form {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.business-form-offices {
    padding: 20px 0px 26.83px 42.75px !important;
    background-color: rgba(69, 183, 206, 0.05) !important;
}

.business-form-offices-title {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 12px !important;
    line-height: 17px !important;
    color: #54595E !important;
}

.business-form-offices-name {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 20px !important;
    color: #68717A !important;
}

.user-form-business-text {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 20px !important;
    color: #68717A !important;
    margin-left: 10px !important;
}

.user-form-business-icon svg path {
    fill: #68717A !important;
}