.recents-title-text {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 20px !important;
    line-height: 115% !important;
    color: #68717A !important;
}

.divider-line-docs {
    border: 1px solid #DEE2E6 !important;
}

.container-doc-div {
    border-radius: 10px;
    background-color: #FFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    margin-top: 14px;
    margin-bottom: 14px;
    padding: 6px;
}

.document-name {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 20px !important;
    display: flex !important;
    align-items: center !important;
    color: #54595E !important;
}

.document-date-text {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 10px !important;
    line-height: 14px !important;
    display: flex !important;
    align-items: center !important;
    color: #ABB5BE !important;
}

.folder-name {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 12px !important;
    line-height: 17px !important;
    color: #5AB6DF !important;
}

.user-name-title {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 12px !important;
    line-height: 17px !important;
    color: #6C757D !important;
}

.download-button {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 12px !important;
    line-height: 17px !important;
    color: #5AB6DF !important;
    cursor: pointer !important;
    text-transform: capitalize !important;
}

.documents-icon-svg-delete {
    height: 14.63px !important;
    width: 16.88px !important;
}

.documents-icon-delete path {
    fill: #DC3545 !important;
}

.documents-icon-svg-folder {
    height: 24px !important;
    width: 24px !important;
}

.documents-icon-folder path {
    fill: #17ACEA!important;
}