 /*Chrome, Safari, Edge, Opera */
 input.noarrows,input::-webkit-outer-spin-button,
 input::-webkit-inner-spin-button{
   -webkit-appearance: none;
   margin: 0;
 }
 
 /* Firefox */
 input[type=number].noarrows {
   -moz-appearance: textfield;
 }