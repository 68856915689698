.arrow-back-icon-svg {
    height: 19.5px !important;
    width: 10.5px !important;
}

.description-table-cell-notifications {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 20px !important;
    text-align: right !important;
    color: #68717A !important;
}

.detail-table-cell-notifications {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 20px !important;
    color: #152536 !important;
}