.extra-padding-for-div-business {
    padding: 56px 0px 58px 29px !important;
}

.big-text-for-business-name-matter {
    font-size: 28px !important;
    line-height: 115% !important;
    font-style: normal !important;
    font-weight: normal !important;
}

.status-row-client-matter {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 20px !important;
    display: flex !important;
    align-items: center !important;
}

.description-table-cell-matters {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 20px !important;
    text-align: right !important;
    color: #68717A !important;
}

.detail-table-cell-matters {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 20px !important;
    color: #152536 !important;
}

.header-cell-matters {
    border-bottom: 2px solid #DEE2E6 !important;
    border-top: 1px solid #DEE2E6 !important;
}

.title-row-align {
    display: flex !important;
    align-items: center !important;
    justify-items: center !important;
    align-content: center !important;
    align-content: center;
}

.access-icon-svg-delete {
    height: 14.63px !important;
    width: 16.88px !important;
}

.access-icon-delete path {
    fill: #DC3545 !important;
}

.access-icon-svg-active {
    height: 10px !important;
    width: 10px !important;
}

.access-icon-active path {
    fill: #28A745 !important;
}
.folder-title-cel-text {
    color: #5AB6DF !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 12px !important;
    line-height: 17px !important;
}
