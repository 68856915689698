.title-page-label {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 21px !important;
    color: #152536 !important;
}

.elements-title {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 22px !important;
    color: #54595E !important;
    display: flex !important;
    align-items: center !important;
}

.elements-subtitle {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 20px !important;
    color: #68717A !important;
    padding-left: 5px !important;
}

.button-delete-page {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 22px !important;
    color: #DC3545 !important;
    background: #F8F9FA !important;
    border-radius: 4px !important;
    height: 44px !important;
    padding: 10px 16px !important;
    text-transform: initial !important;
    size: 25px !important;
}

.button-delete-page path {
    fill: #DC3545 !important;
}

.button-history-page {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 19px !important;
    color: #54595E !important;
    background: #F8F9FA !important;
    border-radius: 4px !important;
    height: 44px !important;
    padding: 10px 16px !important;
    text-transform: initial !important;
    size: 25px !important;
}

.button-history-page path {
    fill: #54595E !important;
}

.button-movil-save {
    min-width: 170px !important;
    height: 42px !important;
    background: #8976B6 !important;
    border-radius: 4px !important;
    padding: 10px 16px !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 22px !important;
    color: #FFFFFF !important;
    text-transform: capitalize !important;
    margin: 16px 20px 20px !important;
}

.button-movil-publish {
    min-width: 91px !important;
    height: 42px !important;
    background: #5AB6DF !important;
    border-radius: 4px !important;
    padding: 10px 16px !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 22px !important;
    color: #FFFFFF !important;
    text-transform: none !important;
    margin: 16px 0 20px 0 !important;
}

.div-content-info {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 750px;
    padding-right: 10px;
}

.div-content-info::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

.div-content-info::-webkit-scrollbar-thumb {
    background: #8976B6;
    border-radius: 4px;
}

.div-content-info::-webkit-scrollbar-thumb:hover {
    background: #9e88d1;
}

.div-content-info::-webkit-scrollbar-track {
    background: #ccc;
    border-radius: 4px;
}

.div-content-info::-webkit-scrollbar-track:hover, .div-content-info::-webkit-scrollbar-track:active {
    background: #d4d4d4;
}