.div-image {
  background-color: #F8F9FA !important;
  border: 2px dashed #CED4DA !important;
  box-sizing: border-box !important;
  border-radius: 20px !important;
  width: 100% !important;
  min-height: 241px !important;
  display: flex;
  justify-content: center;
}

.drag-image-text {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 24px !important;
  line-height: 33px !important;
  color: #68717A !important;
}

.select-images-text {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 24px !important;
  line-height: 33px !important;
  color: #8976B6 !important;
}

.image-description-mobile {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: #6C757D !important;
}

.div-image input {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 241px;
  outline: none;
  opacity: 0;
}

.image-name-text {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 20px !important;
  line-height: 33px !important;
  color: #68717A !important;
  width: 330px !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}

.date-time-image-text {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: #6C757D !important;
}

.preview-image-div-mob {
  max-width: 294px;
  background-color: #FFF;
  margin: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.preview-image-mob {
  width: 100%;
  height: 100%;
  background-size: cover;
}