.item-help-sidebar {
    width: 100% !important;
    height: 35px !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 20px !important;
    justify-content: left !important;
    color: #8976B6 !important;
    margin-left: 5% !important;
}

.item-help-sidebar:last-child {
    margin-bottom: 5% !important;
}

.label-title-help-sidebar {
    height: 40px !important;
    margin-left: 11% !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 22px !important;
    line-height: 30px !important;
    display: flex !important;
    align-items: center !important;
    color: #231E60 !important;
}

.label-subtitle-help-sidebar {
    height: 50px !important;
    margin-left: 11% !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 17px !important;
    display: flex !important;
    align-items: center !important;
    color: #231E60 !important;
}

.item-help-sidebar-icon-svg svg path {
    fill: #8976B6 !important;
    margin-right: 5% !important;
}

.item-help-sidebar-icon {
    color: #8976B6 !important;
    margin-right: 5% !important;
    height: 10px !important;
    width: 20px !important;
}

.menu-list-help-sidebar:focus {
    outline: none !important;
}

.menu-list-help-sidebar .MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected.MuiButtonBase-root {
    background: transparent !important;
    color: #17ACEA !important;
}

.menu-list-help-sidebar .MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected.MuiButtonBase-root>.item-help-sidebar-icon path {
    fill: #17ACEA !important;
}

.divider-help-sidebar {
    /* margin-left: 10% !important; */
    width: 100% !important;
    height: 1px !important;
    background-color: #DEE2E6 !important;
    margin: 10px 0 10px 10% !important;
}