.avatar-image {
    filter: drop-shadow(0px 8px 35px rgba(0, 0, 0, 0.16)) !important;
}

.avatar-image img {
    object-fit: contain !important;
}

.user-name-text {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 28px !important;
    line-height: 115% !important;
    color: #152536 !important;
}

.job-user-tite {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 20px !important;
    color: #152536 !important;
}

.align-divs {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
}

.offices-list-text {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 20px !important;
    color: #68717A !important;
}

.offices-title {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 12px !important;
    line-height: 17px !important;
    color: #54595E !important;
}

.offices-list-div {
    padding: 20px 0px 26.83px 42.75px !important;
    background-color: rgba(69, 183, 206, 0.05) !important;
}

.access-text {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 20px !important;
    color: rgb(108, 117, 125, 0.5);
}

.access-text-active {
    color: #68717A !important;
}

.access-icon-svg svg path {
    fill: rgb(108, 117, 125, 0.5) !important;
}

.access-icon {
    height: 24px !important;
    width: 24px !important;
}
.replication .select-text-field p {
    bottom: -25px !important;
    /* display: block; */
    /* width: 140px; */
    white-space: nowrap;
}
.access-icon-active svg path {
    fill: #68717A !important;
}