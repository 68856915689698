.file-title {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 20px !important;
    line-height: 115% !important;
    color: #231E60 !important;
}

.file-subtitle {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 20px !important;
    color: #6C757D !important;
    margin-bottom: 40px !important;
}

.container-div-modal-file {
    border-radius: 20px !important;
    background-color: #FFF !important;
    padding: 49px !important;
    width: 60%;
}

.container-div-modal-file>.modal-file {
    max-height: 60vh;
    overflow-x: auto;
}

.button-create-folder-file {
    min-width: 200px !important;
    min-height: 44px !important;
    background: #F8F9FA !important;
    border-radius: 4px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 22px !important;
    color: #343A40 !important;
    text-transform: capitalize !important;
}

.file-icon-svg-folder {
    height: 24px !important;
    width: 24px !important;
    margin-right: 5px !important;
}

.file-icon-folder path {
    fill: #343A40 !important;
}

.upload-files-div {
    position: relative;
    background-color: #F8F9FA !important;
    border: 2px dashed #CED4DA !important;
    box-sizing: border-box !important;
    border-radius: 20px !important;
    width: 100% !important;
    min-height: 222px !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    margin-top: 40px !important;
}

.upload-files-input {
    position: absolute;
    border-radius: 20px !important;
    width: 100% !important;
    height: 222px !important;
    margin: 0;
    padding: 0;
    outline: none;
    opacity: 0;
    cursor: pointer;
    z-index: 10000;
}

.upload-files-text {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #6C757D;
}

.upload-files-text label {
    margin: 5px 0;
}

.upload-files-text .title {
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    color: #8976B6;
}

.upload-files-text .title2 {
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    color: #68717A;
}

.file-icon-svg-cloud {
    height: 50px !important;
    width: 50px !important;
}

.file-icon-svg-cloud path {
    fill: #ABB5BE !important;
}

.count-files {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 115%;
    color: #68717A;
    margin-bottom: 5px !important;
    margin-top: 0px;
}

.files-name {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #68717A;
    margin-bottom: 15px !important;
    margin-top: 0px;
    margin-left: 13px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 600px;
}

.files-icon {
    height: 25px !important;
    width: 25px !important;
}

.files-icon path {
    fill: #68717A !important;
}

