.table-head-user {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 22px !important;
    color: #152536 !important;
    border-bottom: 2px solid #DEE2E6 !important;
    width: calc(100% / 4);
}

.table-row-cell-user {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 20px !important;
    display: flex !important;
    align-items: center !important;
}

.table-row-user {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 20px !important;
    color: #68717A !important;
}