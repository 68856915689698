.rdw-editor-toolbar {
    border: none !important;
}

.editor {
    background: #FFFFFF;
    border: 1px solid #CED4DA;
    box-sizing: border-box;
    border-radius: 4px;
    height: 139px;
}

.mobile-icon-svg-map {
    height: 18px !important;
    width: 18px !important;
}

.mobile-icon-map path {
    fill: #5AB6DF !important;
}

/* .rdw-link-modal-target-option {
    display: none;
} */

.rdw-option-active {
    background: lightblue !important;
}

.wrapper {
    max-width: 500px !important;
}

.wrapper .public-DraftEditor-content {
    padding: 10px;
}

.wrapper h1, h2, h3 {
    margin: 0;
    display: flex;
    align-items: flex-start;
}

.wrapper .public-DraftStyleDefault-block {
    margin: 0;
}

.wrapper .rdw-editor-toolbar {
    width: 80%;
}