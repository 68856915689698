.MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 20px !important;
    text-align: center !important;
    color: #54595E !important;
}

.MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 20px !important;
    text-align: center !important;
    color: #54595E !important;
}

.MuiStepLabel-labelContainer {
    width: 100% !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 20px !important;
    text-align: center !important;
    color: #ABB5BE !important;
}

.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiStepIcon-root {
    height: 56px !important;
    width: 56px !important;
    color: #DEE2E6 !important;
}

.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiStepIcon-root.Mui-active {
    color: #5AB6DF !important;
}

.MuiStepConnector-line.MuiStepConnector-lineHorizontal {
    border: none !important;
}

.MuiStepIcon-text {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 12px !important;
    line-height: 33px !important;
    display: flex !important;
    align-items: center !important;
    text-align: center !important;
    fill: #ABB5BE !important;
}

.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiStepIcon-root.Mui-active text {
    fill: #fff !important;
}

.MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    margin-top: 24px !important;
}

.step-container-authorization {
    margin-top: 33px;
    margin-bottom: 45px;
    padding: 28px
}