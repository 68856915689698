.card-content-item-folder-mobile {
    height: min-content !important;
    max-width: 125px !important;
    width: auto !important;
    border-radius: 10px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05) !important;
    background: rgba(90, 182, 223, 0.1) !important;
    border: 1px dashed #5AB6DF !important;
    margin: 5px 17px 5px 0 !important;
    text-align: center !important;
    text-decoration: none !important;
    cursor: pointer !important;
}

.card-text-folder-mobile {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 120.02% !important;
    display: flex !important;
    color: #5AB6DF !important;
    margin-left: 10px !important;
}

.MuiCardContent-root.card-content-item-folder-mobile:last-child {
    padding-bottom: 16px !important;
    margin-right: 0 !important;
}