.tab-select-page-mobile {
  padding: 23px 0px 0px 0px !important;
}

.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.button-app-save {
  color: #FFFFFF !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 22px !important;
  text-transform: capitalize !important;
}

.new-page.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected {
  color: #8976B6 !important;
}