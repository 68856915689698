.description-table-cell {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 20px !important;
    text-align: right !important;
    color: #68717A !important;
}

.detail-table-cell {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 20px !important;
    color: #152536 !important;
}

.header-cell {
    border-bottom: 2px solid #DEE2E6 !important;
    border-top: 1px solid #DEE2E6 !important;
}

.button-print-download {
    padding: 12px 16px  !important;
    background: #F8F9FA !important;
    border-radius: 4px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    text-align: center !important;
    color: #343A40 !important;
    text-transform: capitalize !important;
}
