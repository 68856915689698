.button-mobile {
    min-height: 96px !important;
    max-width: 96px !important;
    width: 96px !important;
    align-items: center !important;
    justify-content: center !important;
    background: rgba(90, 182, 223, 0.2) !important;
    border: 1px dashed #5AB6DF !important;
    text-align: center !important;
}

.mobile-button-icon-svg {
    height: 32px !important;
    width: 32px !important;
}

.mobile-button-icon path {
    fill: #5AB6DF !important;
}

.div-images {
    background-color: #F8F9FA !important;
    border: 2px dashed #CED4DA !important;
    box-sizing: border-box !important;
    border-radius: 20px !important;
    width: 100% !important;
    min-height: 241px !important;
}

.div-images input {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 96px;
    outline: none;
    opacity: 0;
}

.button-text-upload {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 12px !important;
    line-height: 17px !important;
    text-align: center !important;
    color: #5AB6DF !important;
}