.accordion-title-text {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 20px !important;
    color: #152536 !important;
}

.results-icon-svg {
    margin-right: 10px;
}

.results-icon-svg svg path {
    fill: #442F91!important;
}

.accordion-sumary-row-title {
    background: #FFFFFF !important;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05) !important;
    border-radius: 20px !important;
}

.accordion-sumary-row-title::before {
    position: unset !important;
}