.mobile-form-icon-svg-delete {
    height: 16px !important;
    width: 16px !important;
}

.mobile-form-icon-delete path {
    fill: #DC3545 !important;
}

.label-required-text {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 20px !important;
    color: #68717A !important;
}
