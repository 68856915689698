.modal-title-text {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 20px !important;
    line-height: 115% !important;
    color: #231E60 !important;
    margin-bottom: 38px !important;
}

.button-spam-no-spam {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 22px !important;
    color: #343A40 !important;
    background: #F8F9FA !important;
    border-radius: 4px !important;
    height: 44px !important;
    padding: 11px 16px !important;
    text-transform: capitalize !important;
    size: 25px !important;
}

.no-spam-icon svg path {
    fill: #54595E !important;
}

.button-close-cross {
    color: #5AB6DF !important;
    text-transform: capitalize !important;
    min-width: 0px !important;
    padding: 0px !important;
    align-items: flex-start !important;
}

.button-close-cross:hover {
    background-color: transparent !important;
}

.divider-style {
    border: 1px solid #DEE2E6 !important;
    margin-bottom: 36px !important;
}

.title-data-client {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 22px !important;
    color: #152536 !important;
}

.subtitle-data-client {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 20px !important;
    color: #68717A !important;
}

.tab-select-page-assigments-data-user {
    padding: 35px 0 0 0 !important;
}

.tab-select-page-assigments-clients-assigment {
    padding: 35px 0 0 0 !important;
}

.icon-for-clients-text svg {
    height: 24px !important;
    width: 24px !important
}

.icon-for-clients-text svg path {
    fill: #ABB5BE !important;
}

.container-div-modal {
    border-radius: 20px !important;
    background-color: #FFF !important;
    padding: 49px !important;
    width: 70%;
}

.container-div-modal>div {
    max-height: 60vh;
    overflow-x: auto;
}

.container-div-modal > div::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

.container-div-modal > div::-webkit-scrollbar-thumb {
    background: #8976B6;
    border-radius: 4px;
}

.container-div-modal > div::-webkit-scrollbar-thumb:hover {
    background: #9e88d1;
}

.container-div-modal > div::-webkit-scrollbar-track {
    background: #ccc;
    border-radius: 4px;
}

.container-div-modal > div::-webkit-scrollbar-track:hover, .container-div-modal > div::-webkit-scrollbar-track:active {
    background: #d4d4d4;
}

.clients-assigment-title {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 22px !important;
    color: #68717A !important;
}