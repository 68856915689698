.cell-info{
    display: flex;
    
}
.cell-info span{
    margin: 0 20px 0 35px;
}
.cell-info span:nth-of-type(1){
    width: 65px;
}
.cell-info.licenses {
    display: flex;
    justify-content: space-between;
    width: 45%;
    margin: 10px 0;
    font-size: 19px;
}
.cell-info.licenses span:nth-of-type(1) {
    width: 135px;
}
.cell-info.licenses span:nth-of-type(2) {
    opacity: 0.75;
}

.historyRow {
    width: 100%;
    padding: 15px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: -webkit-center;
    align-content: center;
    align-items: center;
}
.historyRow.headers {
    border-bottom: 2px solid rgba(39,39,39,.15294117647058825);
    
}
.historyRow {
    border-bottom: 1px solid #27272727;
}

.historyColumn {
    width: calc(22% - 10px);
    max-width: calc(23% - 10px);
}
.historyRow:not(.headers) {
    opacity: 0.7;
}
.plan-warning {
    color: #ce1522;
    background-color: #9f182205;
    padding: 20px;
    border: 0.5px solid #9f182222;
}
.reg-div.cards.profile {
    min-width: 800px;
}

.reg-div.cards.profile {
    display: flex;
    margin-top: 40px;
    padding: 0 0px;
    height: auto;
    /* min-height: 300px; */
    margin-bottom: 30px;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
}
.reg-card-container.current,.reg-card-container.disabled {
    opacity: 0.7;
    filter: grayscale(1);
    /* mix-blend-mode: saturation; */
}
.basetext {
    font-weight: 550;
    opacity: 0.55;
}
.payment-footer {
    display: flex;
    /* position: absolute; */
    text-align: left;
    flex-direction: row;
    margin-top: 100px;
    font-weight: 500;
    align-items: flex-start;
    justify-content: space-between;
}
.payment-footer img {
    /* max-height: 200px; */
    max-width: fit-content;
    /* position: absolute; */
    right: 0;
    
    /* bottom: 0; */
    /* right: -0px; */
}
.payment-header {
    display: flex;
    position: relative;
    width: 150%;
    margin-bottom: 5%;
    /* padding: 10px 80px; */
    justify-content: space-between;
}
.payment-title {
    width: 150%;
    padding: 20px 0;
    margin-bottom: 40px;
    border-bottom: 3px solid #17abeab6;
}
.payment-content {
    padding: 0px 0px;
}
.print-only {
    visibility: hidden;
    position: absolute;
    color: #27272727;
  }
  @media print {
    .print-only {
      display: block;
      visibility: visible;
    }
  }
.title-wrap p{
    font-size: 2em !important;
    font-weight: 500 !important;
    color:#8976B6 !important;
}
.title-wrap.sub p{
    font-size: 1.2em !important;
    font-weight: 500 !important;
    color:#17ACEA !important;
}
.payment-details {
    width: 40%;
}
.payment-details>div {
    display: flex;
    line-height: 2em;
    justify-content: space-between !important; 
}
.title-wrap.sub {
    border-bottom: 1px solid #17ACEA;
    display: inline-block;
    margin-bottom: 20px;
}
.payment-title>p {
    margin-bottom: 40px;
}
div#invoice {
    font-size: 1.2em;
    line-height: 20px !important;
    color: #6C757D !important;
    padding-left: 40px;
    padding-right: 40px;
}
* :not(.text span, .wrapper span) {
    font-family: var(--font-family-roboto) !important;
}
