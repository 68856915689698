.container-div-modal-record {
    border-radius: 20px !important;
    background-color: #FFF !important;
    padding: 49px !important;
    width: 90%;
}

.record-title {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 20px !important;
    line-height: 115% !important;
    color: #231E60 !important;
}

.record-page-title {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 24px !important;
    line-height: 33px !important;
    color: #68717A !important;
    margin-bottom: 5px !important;
}

.record-page-subtitle {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 22px !important;
    color: #68717A !important;
    margin-bottom: 10px !important;
}