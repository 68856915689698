.select-text-field {
    width: 100% !important;
}

.select-text-field>div {
    background: #FFFFFF !important;
    border-color: #CED4DA !important;
    border-radius: 4px !important;
    height: 38px !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 21px !important;
    color: #6C757D !important;
}

.select-text-field .MuiSelect-select {
    padding: 8px 16px !important;
}

.icon .MuiSelect-select {
    padding: 8px 35px 8px 5px !important;
}

.select-field-item {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 21px !important;
    color: #6C757D !important;
    background-color: #FFF !important;
    width: 100% !important;
    min-height: 30px !important;
}

.select-text-field p {
    position: initial;
    bottom: -35px;
    width: 104px !important;
    max-width: 104px !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 12px;
    line-height: 16px;
    color: #DC3545;
    margin-left: 0 !important;
    margin-right: 0 !important;
}