.button-edit-client {
    background: #F8F9FA !important;
    border-radius: 4px !important;
    width: 78px !important;
    height: 39px !important;
    color: #343A40 !important;
    text-transform: capitalize !important;
}

.big-text-for-business-name {
    font-size: 28px !important;
    line-height: 115% !important;
    font-style: normal !important;
    font-weight: normal !important;
}

.office-direction-title {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 20px !important;
    color: #152536 !important;
}

.status-row-client {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 20px !important;
    display: flex !important;
    align-items: center !important;
}

.extra-padding-for-div {
    padding: 56px 49px 51px 29px !important;
}
