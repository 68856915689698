.documents-div {
    border-top: 1px solid #DEE2E6!important;
    border-bottom: 1px solid #DEE2E6!important;
    width: 100% !important;
    height: 157px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.no-documents-text {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 22px !important;
    color: #6C757D !important;
}