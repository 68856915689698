.card-content-item-folder {
    min-height: 100px !important;
    max-width: 115px !important;
    width: 115px !important;
    border-radius: 10px !important;
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05) !important;
    margin-right: 18px !important;
    margin-bottom: 33px !important;
    text-align: center !important;
    text-decoration: none !important;
}

/* .card-content-item-folder:nth-child(-n+3) {
    margin-right: auto !important;
} */

.card-content-item-folder:last-child {
    margin-right: 0 !important;
}

.card-content-item-folder:hover {
    background: #FFFFFF !important;
    box-shadow: 0px 4px 10px #E1E1E1 !important;
    color: #5AB6DF !important;
    border: 1px solid #5AB6DF !important;
}

.icon-svg-folder {
    height: 28px !important;
    width: 27px !important;
}

.icon-folder path {
    fill: #5AB6DF!important;
}

.card-text-folder {
    font-size: 13px !important;
    color: #8976B6 !important;
    font-style: normal !important;
    font-weight: normal !important;
    line-height: 16px !important;
    margin-top: 16px !important;
}

.card-text-counter {
    font-size: 10px !important;
    color: #ABB5BE !important;
    font-style: normal !important;
    font-weight: normal !important;
    line-height: 14px !important;
    margin-top: 15px !important;
}

.card-content-item-add-folder {
    min-height: 100px !important;
    max-width: 115px !important;
    width: 115px !important;
    border-radius: 10px !important;
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05) !important;
    margin-right: 18px !important;
    margin-bottom: 33px !important;
    text-align: center !important;
    text-decoration: none !important;
    border: 1px solid #ABB5BE !important;
    cursor: pointer !important;
}

.card-text-add-folder {
    font-size: 13px !important;
    color: #ABB5BE !important;
    font-style: normal !important;
    font-weight: normal !important;
    line-height: 16px !important;
    margin-top: 2% !important;
}

.icon-svg-folder-add {
    height: 28px !important;
    width: 27px !important;
}

.icon-folder-add path {
    fill: #ABB5BE!important;
}