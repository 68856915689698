.label-reset-password {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 20px !important;
    color: #54595E !important;
}

.button-reset-password {
    width: 94px !important;
    height: 42px !important;
    background-color: #5AB6DF !important;
    border-radius: 4px !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 22px !important;
    display: flex !important;
    align-items: center !important;
    text-align: center !important;
    color: #FFFFFF !important;
    text-transform: capitalize !important;
    margin-top: 2% !important;
}

.button-reset-password:hover {
    background-color: #54afd6 !important;
}
@media (min-width:990px) {
    .logo-small{
        display: none !important;
    }    
}
@media (max-width:989px) {
    .landing-logo {
        display: none !important;
    }    
    .pass-form-content {
        height: auto !important;
        padding: 3% 6% 0px 6% !important;
    }
    .login-form-card {
        padding-right: 0% !important;
    }
}
.logo-small {
    height: 20vh;
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
}