.clients-number-assigned {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 20px !important;
    color: #152536 !important;
}

.align-divs-clients {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
}

.access-text-clients {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 20px !important;
    color: rgb(108, 117, 125, 0.5);
}

.access-text-active-clients {
    color: #68717A !important;
}

.table-row-clients-cell {
    padding: 6px 0px 14px 16px !important;
}