.card-container {
    background-color: #F8F9FA;
    border: 1 solid #CED4DA;
    border-radius: 20px !important;
    padding: 30px 35px !important;
    min-height: 218px !important;
}

.card-content-item {
    min-height: 100px !important;
    max-width: 130px !important;
    width: 130px !important;
    border-radius: 20px !important;
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    background: rgb(235, 229, 252, 0.4) !important;
    margin-right: 39px !important;
    margin-bottom: 40px !important;
    text-align: center !important;
    text-decoration: none !important;
}

.card-content-item:last-child {
    margin-right: 0 !important;
}

.card-content-item:hover {
    background: #FFFFFF !important;
    box-shadow: 0px 4px 10px #E1E1E1 !important;
    color: #5AB6DF !important;
}

.card-content-item:hover .card-text {
    color: #5AB6DF !important;
}

.card-content-item:hover svg path {
    fill: #5AB6DF !important;
}

.card-text {
    font-size: 15px !important;
    color: #231E60 !important;
    font-style: normal !important;
    font-weight: normal !important;
    line-height: 20px !important;
    margin-top: 2% !important;
}

.card-title {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 22px !important;
    display: flex !important;
    align-items: center !important;
    color: #6C757D !important;
    margin-bottom: 24px !important;
}