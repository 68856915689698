.config-app-language-form-app {
    height: 16px;
    display: block;
    position: relative;
    padding-left: 26px;
    margin-right: 32px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 20px !important;
    color: #68717A !important;
}

.config-app-language-form-app input {
    display: none;
}

.config-app-language-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #FFF;
    border: 1px solid #ABB5BE;
    border-radius: 50%;
}

.config-app-language-form-app input:checked~.config-app-language-checkmark {
    background-color: #5AB6DF;
    border: 1px solid #5AB6DF;
}

.config-app-language-checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.config-app-language-form-app input:checked~.config-app-language-checkmark:after {
    display: block;
}

.config-app-language-form-app .config-app-language-checkmark:after {
    top: 5px;
    left: 5px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #FFF;
}

.config-app-description {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 12px !important;
    line-height: 17px !important;
    color: #68717A !important;
}

.config-app-logo-div-ios {
    position: absolute;
    width: 49px;
    height: 49px;
    background-color: #FFF;
    border-radius: 9px;
    top: 86px;
    left: 49px;
}

.config-app-logo-ios {
    width: 100%;
    height: 100%;
    border-radius: 9px;
    background-size: cover;
    background-position: center;
}

.app-name-ios {
    position: absolute;
    top: 52px;
    left: 0;
    width: 49px;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 10px;
    color: #FFF;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.config-app-logo-div-android {
    position: absolute;
    width: 46px;
    height: 46px;
    border-radius: 50%;
    background-color: #FFF;
    top: 31px;
    left: 46px;
}

.config-app-logo-android {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
}

.app-name-android {
    width: 46px;
    position: absolute;
    top: 51px;
    left: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 11px;
    color: #FFF;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.device-name {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 12px !important;
    line-height: 17px !important;
    margin: 0 10px 0 10px !important;
    color: #CED4DA;
    cursor: pointer;
}
.noBorderInput fieldset {
    border: none !important;
}
.noBorderInput input {
    font-size: 26px !important;
}

.device-name-active {
    color: #5AB6DF;
}

.app-config-alert {
    border: 1px solid;
    padding: 10px;
    width: 50%;
    margin: 15px  0 !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 13px !important;
    line-height: 21px !important;
    color: #DC3545 !important;
    background-color: #DC354505 !important;
}
.app-config-alert-description {
    display: flex !important;
    align-items: center !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 19px !important;
    color: #6C757D !important;
}

.app-config-alert-icon {
    margin-right: 8px;
}
