.business-text {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 22px !important;
    color: #442F91 !important;
}

.admin-business-text {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 24px !important;
    line-height: 33px !important;
    color: #54595E !important;
}

.help-label-text {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 19px !important;
    display: inline-block;
    color: #68717A !important;
    margin-bottom: 6px !important;
}
.p{
    margin-top: 16px !important;
}
.paragraph-text {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 20px !important;
    color: #68717A !important;
}
.strong{
font-weight: bold !important;
}
.help-screen.header {
    background: rgb(232 239 247);
    display: flex !important;
    flex-direction: row !important;
}
.help-screen.header>div:nth-of-type(2) {
    border-left: 2px solid #2222;
    margin-right: 30px;
    padding-left: 50px;
}
.enum {
    /* margin: 0; */
    /* padding: 0; */
    display: inline-block;
    margin-right: 11px;
    margin-left: 7px;
    background-color: rgb(91 169 233);
    width: 20px;
    height: 20px;
    font-weight: 600;
    color: white;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',sans-serif !important;
    border-radius: 90px;
    text-align: center;
    line-height: 18px;
}
.imgdecor {
    border: 2px solid lightblue;
    box-shadow: -1px 5px 5px #7773 !important;
}

.card-image-first-step {
    border: 1px solid #CED4DA !important;
    border-radius: 0px !important;
    box-shadow: none !important;
}

.MuiMobileStepper-dot.MuiMobileStepper-dotActive {
    background-color: #5AB6DF !important;
}

.MuiMobileStepper-dot {
    background-color: rgba(90, 182, 223, 0.3) !important;
    margin: 0 5px !important;
    width: 13px !important;
    height: 13px !important;
}