.select-phone-input {
    background: #FFFFFF !important;
    border-color: #CED4DA !important;
    border-radius: 4px !important;
    height: 38px !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 21px !important;
    color: #6C757D !important;
    padding: 8px 20px 9px 10px !important;
    margin-top: 14% !important;
}

.app-form-title {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 22px !important;
    color: #68717A !important;
    margin-bottom: 23px !important;
}

.app-form-subtitle {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 20px !important;
    color: #6C757D !important;
    margin-bottom: 24px !important;
}

.image-div {
    background-color: #F8F9FA !important;
    border: 2px dashed #CED4DA !important;
    box-sizing: border-box !important;
    border-radius: 20px !important;
    width: 100% !important;
    min-height: 222px !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 20px;
}

.button-add-image {
    background: #FFFFFF !important;
    border: 1px solid #343A40 !important;
    box-sizing: border-box !important;
    border-radius: 4px !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 22px !important;
    color: #343A40 !important;
    height: 42px !important;
    padding: 10px 16px !important;
    margin-bottom: 30px !important;
    text-transform: capitalize !important;
}

.add-image-title {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 12px !important;
    line-height: 17px !important;
    color: #68717A !important;
}

.card-img-form {
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-size: cover !important;
    border-radius: 8x !important;
    max-height: 200px !important;
    max-width: 400px !important;
    object-fit: contain !important;
}

/* Tabs */

.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary {
    text-transform: capitalize !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 21px !important;
    text-align: center !important;
    color: #54595E !important;
}

.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected {
    color: #5AB6DF !important;
}

.MuiTabs-indicator {
    background-color: #5AB6DF !important;
    height: 3px !important;
}

.tab-select-page {
    padding: 33px 34px 0px 34px !important;
}

.button-archive {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 22px !important;
    color: #343A40 !important;
    background: #F8F9FA !important;
    border-radius: 4px !important;
    height: 44px !important;
    padding: 11px 16px !important;
    text-transform: capitalize !important;
    size: 25px !important;
}

.tab-select-page-clients {
    padding: 23px 0px 8px 0px !important;
}

.select-text-field p.Mui-error {
    position: initial;
    max-width: 200px !important;
}