.label-not-same-password {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 12px !important;
    line-height: 17px !important;
    color: #DC3545 !important;
    margin-top: 3% !important;
}

.label-generate-password {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 22px !important;
    color: #68717A !important;
}

.change-pass-alert {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 20px !important;
    color: #DC3545 !important;
    border: 1px solid;
    margin: 20px 0 !important;
}