.icon-svg-mobile {
    margin-right: 14.5px;
}

.accordion-sumary-row-title-mobile {
    background: #FFFFFF !important;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05) !important;
    border-radius: 20px !important;
    border: 1px solid #5AB6DF !important;
}


.accordion-sumary-row-title-mobile .MuiAccordionSummary-content {
    display: flex;
    flex-direction: column;
}

.MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded {
    border-bottom: 1px solid #5AB6DF !important;
}

.panel-details {
    margin-top: 10px;
}

.accordion-title-text-mobile {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 22px !important;
    color: #5AB6DF !important;
}

.mobile-icon-svg-delete {
    height: 22.5px !important;
    width: 19.5px !important;
}

.mobile-icon-delete path {
    fill: #DC3545 !important;
}

.mobile-content-alert {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 16px !important;
    text-align: left !important;
    color: #484848 !important;
    display: flex !important;
    align-items: center !important;
}

.search-google-maps {
    background: #FFFFFF !important;
    border: 1px solid #CED4DA !important;
    border-radius: 4px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 21px !important;
    color: #6C757D !important;
    margin-bottom: 20px !important;
    mix-blend-mode: normal !important;
    vertical-align: middle !important;
    padding: 0 16px 0 16px !important;
    height: 38px !important;
    width: 92%;
}

.search-google-maps::placeholder {
    color: #ABB5BE !important;
}

.language-code {
    min-width: 60px !important;
}