.notifications-text-clients {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 20px !important;
    color: rgb(108, 117, 125, 0.5) !important;
}

.notifications-text-active-clients {
    color: #68717A!important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 20px !important;
}

.dest-disabled * {
    color: #CED4DA !important;
}