.language-form {
    height: 16px;
    display: block;
    position: relative;
    padding-left: 26px;
    margin-right: 32px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 20px !important;
    color: #68717A !important;
    text-transform: capitalize !important;
}

.language-form input {
    display: none;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #FFF;
    border: 1px solid #ABB5BE;
    border-radius: 50%;
}

.language-form input:checked~.checkmark {
    background-color: #5AB6DF;
    border: 1px solid #5AB6DF;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.language-form input:checked~.checkmark:after {
    display: block;
}

.language-form .checkmark:after {
    top: 5px;
    left: 5px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #FFF;
}

.image-confi-div {
    background-color: #F8F9FA !important;
    border: 2px dashed #CED4DA !important;
    box-sizing: border-box !important;
    border-radius: 20px !important;
    width: 100% !important;
    min-height: 222px !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 20px;
}

.card-image-confi {
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-size: cover !important;
    border-radius: 8x !important;
    max-height: 200px !important;
    max-width: 400px !important;
    object-fit: contain !important;
}

.button-add-image-confi {
    background: #FFFFFF !important;
    border: 1px solid #343A40 !important;
    box-sizing: border-box !important;
    border-radius: 4px !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 22px !important;
    color: #343A40 !important;
    height: 42px !important;
    padding: 10px 16px !important;
    margin-bottom: 20px !important;
    text-transform: capitalize !important;
}

.image-description-confi {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 12px !important;
    line-height: 17px !important;
    color: #68717A !important;
}

.social-network-icon {
    width: 40px !important;
    margin-bottom: 11px !important;
    margin-right: 15px !important;
}