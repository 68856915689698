.doc-title-text {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 20px !important;
    line-height: 115% !important;
    color: #68717A !important;
}

.divider-line {
    border: 1px solid #DEE2E6 !important;
}

.documents-text {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 20px !important;
    color: #68717A !important;
}

.number-of-files {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 12px !important;
    line-height: 17px !important;
    color: #68717A !important;
}

.folder-icon-row {
    height: 24px !important;
    width: 24px !important;
}

.folder-icon-svg-row path {
    fill: #6C757D !important;
}

.authorizations-icon-svg-delete {
    height: 14.63px !important;
    width: 16.88px !important;
}

.authorizations-icon-delete path {
    fill: #DC3545 !important;
}
