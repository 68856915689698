.modal-title-text {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 20px !important;
    line-height: 115% !important;
    color: #231E60 !important;
    margin-bottom: 38px !important;
}

.tab-select-page-assigments-data-user {
    padding: 35px 0px 0px 0px !important;
}

.tab-select-page-assigments-clients-assigment {
    padding: 35px 0px 0px 0px !important;
}

.container-div-modal-create-update-customer {
    border-radius: 20px !important;
    background-color: #FFF !important;
    padding: 49px !important;
    width: 70%;
}

.container-div-modal-create-update-customer>div {
    max-height: 60vh;
    overflow-x: auto;
}

.container-div-modal-create-update-customer>div::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

.container-div-modal-create-update-customer>div::-webkit-scrollbar-thumb {
    background: #8976B6;
    border-radius: 4px;
}

.container-div-modal-create-update-customer>div::-webkit-scrollbar-thumb:hover {
    background: #9e88d1;
}

.container-div-modal-create-update-customer>div::-webkit-scrollbar-track {
    background: #ccc;
    border-radius: 4px;
}

.container-div-modal-create-update-customer>div::-webkit-scrollbar-track:hover, .container-div-modal-create-update-customer>div::-webkit-scrollbar-track:active {
    background: #d4d4d4;
}

.clients-assigment-title {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 22px !important;
    color: #68717A !important;
}

.button-close-cross {
    color: #5AB6DF !important;
    text-transform: capitalize !important;
    min-width: 0px !important;
    padding: 0px !important;
    align-items: flex-start !important;
}

.button-close-cross:hover {
    background-color: transparent !important;
}

.divider-style {
    border: 1px solid #DEE2E6 !important;
    margin-bottom: 36px !important;
}
div#paymentMethod-accordion {
    margin-top: 35px;
    display: contents;
}