.folder-text-result {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 22px !important;
    color: #5AB6DF !important;
    margin-right: 10px !important;
}

.folder-results-name {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 22px !important;
    color: #68717A !important;
}

.results-icon-svg-folder {
    height: 24px !important;
    width: 24px !important;
}

.results-icon-folder path {
    fill: #5AB6DF!important;
}

.divider-line-results {
    border: 1px solid #DEE2E6 !important;
    margin: 22px 0px 22px!important
}

.container-results-div {
    border-radius: 10px;
    background-color: #FFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    margin-top: 14px;
    margin-bottom: 14px;
    padding: 6px;
}

.document-name-results {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 20px !important;
    display: flex !important;
    align-items: center !important;
    color: #54595E !important;
}

.document-date-text-results {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 10px !important;
    line-height: 14px !important;
    display: flex !important;
    align-items: center !important;
    color: #ABB5BE !important;
}

.folder-name-results {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 12px !important;
    line-height: 17px !important;
    color: #5AB6DF !important;
}

.user-name-title-results {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 12px !important;
    line-height: 17px !important;
    color: #6C757D !important;
}

.download-button-results {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 12px !important;
    line-height: 17px !important;
    color: #5AB6DF !important;
    cursor: pointer !important;
    text-transform: capitalize !important;
}

.documents-icon-svg-delete-results {
    height: 14.63px !important;
    width: 16.88px !important;
}

.documents-icon-delete-results path {
    fill: #DC3545 !important;
}
