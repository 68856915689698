.root-appbar {
    overflow: hidden;
    display: flex;
}

.drawer {
    height: 100%;
    width: 320px !important;
}

.drawer>div {
    margin-top: 85px !important;
    width: 300px !important;
    background-color: #F8F9FA !important;
    border-right: 0 !important;
    overflow-y: auto;
    overflow-x: hidden !important;
    height: calc(100vh - 85px) !important;
}

.item-sidebar {
    width: 100% !important;
    height: 50px !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 17px !important;
    line-height: 17px !important;
    justify-content: left !important;
    color: #231E60 !important;
    margin-left: 10% !important;
}

.item-sidebar:last-child {
    margin-bottom: 5% !important;
}

.label-title-sidebar {
    margin-left: 10% !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 22px !important;
    display: flex !important;
    align-items: center !important;
    color: #6C757D !important;
    height: 50px !important;
}

.item-sidebar-icon-svg svg path {
    fill: #231E60 !important;
    margin-right: 10% !important;
}

.item-sidebar-icon {
    color: #231E60 !important;
    margin-right: 10% !important;
    height: 20px !important;
    width: 20px !important;
}

.menu-list-sidebar:focus {
    outline: none !important;
}

.menu-list-sidebar .MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected.MuiButtonBase-root.item-sidebar {
    background: #8976B6 !important;
    border-radius: 9px !important;
    width: 89% !important;
    color: #FFFFFF !important;
}

.MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected.MuiButtonBase-root.item-sidebar>.item-sidebar-icon path {
    fill: #FFFFFF !important;
}

.drawer>div::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

.drawer>div::-webkit-scrollbar-thumb {
    background: #8976B6;
    border-radius: 4px;
}

.drawer>div::-webkit-scrollbar-thumb:hover {
    background: #9e88d1;
}

.drawer>div::-webkit-scrollbar-track {
    background: #ccc;
    border-radius: 4px;
}

.drawer>div::-webkit-scrollbar-track:hover, .drawer>div::-webkit-scrollbar-track:active {
    background: #d4d4d4;
}