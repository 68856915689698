.job-user-form {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 20px !important;
    color: #152536 !important;
}

.user-name-form {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 28px !important;
    line-height: 115% !important;
    color: #152536 !important;
}

.select-text-field p {
    position: initial;
    bottom: -35px;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 12px;
    line-height: 16px;
    color: #DC3545 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.avatar-user-form {
    filter: drop-shadow(0px 8px 35px rgba(0, 0, 0, 0.16)) !important;
    cursor: pointer;
}

.avatar-user-form::after {

    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;
    background: url('../../../assets/Icons/camera.svg') no-repeat 50% 40%, linear-gradient(0deg, rgba(90, 182, 223, 0.8), rgba(90, 182, 223, 0.8)) !important;
    box-shadow: 0px 8px 35px rgba(0, 0, 0, 0.16) !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 11px !important;
    line-height: 28px !important;
    color: #FFFFFF !important;
    padding-top: 40% !important;
}

.user-form-business-text {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 20px !important;
    color: #68717A !important;
    margin-left: 10px !important;
}

.user-form-business-icon svg path {
    fill: #68717A !important;
}

.business-user-form {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.business-form-offices {
    padding: 20px 0px 26.83px 42.75px !important;
    background-color: rgba(69, 183, 206, 0.05) !important;
}

.business-form-offices-title {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 12px !important;
    line-height: 17px !important;
    color: #54595E !important;
}

.business-form-offices-name {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 20px !important;
    color: #68717A !important;
}

.button-add-image {
    text-transform: none !important;
}

.button-active-inactive {
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    color: #343A40 !important;
    background: #F8F9FA !important;
    border-radius: 4px !important;
    height: 44px !important;
    padding: 11px 16px !important;
    text-transform: capitalize !important;
    size: 25px !important;
}

.button-active-inactive svg path {
    fill: #54595E !important;
}

.button-active-inactive svg {
    margin-right: 5px !important;
}