.folder-title {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 20px !important;
    line-height: 115% !important;
    color: #231E60 !important;
}

.folder-subtitle {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 20px !important;
    color: #6C757D !important;
    margin-bottom: 40px !important;
}

.container-div-modal-folder {
    border-radius: 20px !important;
    background-color: #FFF !important;
    padding: 49px !important;
    width: 60%;
}

.container-div-modal-folder>div {
    max-height: 60vh;
    overflow-x: auto;
}
.folder-text-container .input-texfield p {
    position: absolute;
    bottom: -22px;
}